const CHILD_OBJECT_INVALID = 'child_object_invalid';
const CHILD_REQUIRED = 'child_required';
const CHILD_TYPE_INVALID = 'child_type_invalid';
const CHILD_UNKNOWN = 'child_unknown';
const FIRST_CHILD_OBJECT_INVALID = 'first_child_object_invalid';
const FIRST_CHILD_TYPE_INVALID = 'first_child_type_invalid';
const LAST_CHILD_OBJECT_INVALID = 'last_child_object_invalid';
const LAST_CHILD_TYPE_INVALID = 'last_child_type_invalid';
const NODE_DATA_INVALID = 'node_data_invalid';
const NODE_IS_VOID_INVALID = 'node_is_void_invalid';
const NODE_MARK_INVALID = 'node_mark_invalid';
const NODE_TEXT_INVALID = 'node_text_invalid';
const PARENT_INVALID = 'parent_invalid';
const PARENT_TYPE_INVALID = 'parent_type_invalid';

export {
  CHILD_OBJECT_INVALID,
  CHILD_REQUIRED,
  CHILD_TYPE_INVALID,
  CHILD_UNKNOWN,
  FIRST_CHILD_OBJECT_INVALID,
  FIRST_CHILD_TYPE_INVALID,
  LAST_CHILD_OBJECT_INVALID,
  LAST_CHILD_TYPE_INVALID,
  NODE_DATA_INVALID,
  NODE_IS_VOID_INVALID,
  NODE_MARK_INVALID,
  NODE_TEXT_INVALID,
  PARENT_INVALID,
  PARENT_TYPE_INVALID,
};
